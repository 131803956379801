import { ENTITIES } from '../../../config/entities';
import axios from '../axios';
import APIService from '../service';

export default class BenefitPromocodesAPIService extends APIService {
  constructor() {
    super(ENTITIES.benefitPromocode);
  }

  setIdToApiName(id) {
    this.apiName = this.apiName.replace(':id', id);
  }

  resetApiName() {
    this.apiName = ENTITIES.benefitPromocode.api;
  }

  registerRowUpdateCallback(callback) {
    this.onRowUpdateCallback = callback;
  }

  async getAll(params = {}) {
    const queryParams = this._paramsToQueryParams({ ...params, pageSize: 1000 });

    const res = await axios.get(`/${this.apiName}?${queryParams}`);

    return res.data?.promocodes;
  }
}
